// Include any default variable overrides here (though functions won't be available)

$carousel-control-width:             7%;
$carousel-control-opacity:            1;
$font-family-base:            Noto Sans;
$font-weight-normal:          300;
$font-weight-bold:            400;
$font-weight-bolder:          400;
$headings-font-weight:        300;
$headings-margin-bottom:      1rem;
$nav-link-font-weight:        400;
$link-color:                  #486795;

@import "bootstrap/bootstrap";
// Web fonts
@import "fonts";

// Then add additional custom code here

/* Show navbar fixed to the top */
body {
  min-height: 75rem;
  padding-top: 4.5rem;
  background-color: #ebeded;
}

.navbar-nav .nav-item .nav-link.active {
    text-decoration: underline;
    text-decoration-color: #92adbc;
    text-decoration-thickness: 0.5em;
    text-underline-offset: 0.6em;
}

.header-image-full {
  object-fit: cover;
  height: 400px;
  max-width: 100%;
  width: 100%;
}

.header-bg-color {
  background-color: #92adbc;
}

.gallery-image-slider {
  object-fit: cover;
  max-width: 100%;
  width: 100%;
}